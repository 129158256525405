<template>
  <div>
    <div class="jianjie">
      <p class="msg">
        <span class="wan">萝卜猎手HR联盟平台简介</span>
        <!-- <span class="wan">萝卜猎手简介</span> -->
        <a href="#" @click="$router.go(-1)" class="u_btn_add">
          <img class="del" src="../assets/Toast_closed.png" alt="" />
        </a>
        <!-- <img class="del" src="../img/Toast_closed.png" alt="" /> -->
      </p>
      <img class="brief" src="../assets/brief.png" alt="" />
      <div class="hr">
        <!-- <p class="jian">萝卜猎手HR联盟平台简介</p> -->
        <!-- <p class="jian">萝卜简介</p> -->
        <p class="jie">
          求职互助平台是由萝卜猎手发起，20万求职者和5万家中大型企业共同参与的共享互助平台（公益组织）。平台向参与的人才和企业提供人才推荐（候选人授权的情况下）、职业培训、公益讲座等双向服务，帮助求职人才和需求企业快速对接。
        </p>
        <!-- <p class="jie">
          要学会变废为宝这个技能，将日常生活的段子进行二次回收利用。除了调侃幽默之外，还可以当作产品文案使用。这是一种投机取巧，更是一种文案训练。
          比如，“来啊，加班啊，反正有大把头发”。
        </p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.jianjie {
  width: 100%;
  height: 100%;
  /* background: rgba(246, 96, 26, 0.25); */
}
.brief {
  display: block;
  margin: 1.74rem auto 0;
  width: 5.23rem;
  height: 3.64rem;
}
.msg {
  height: 0.88rem;
  line-height: 0.88rem;
  /* border-bottom: solid 0.01rem #EEEEEE; */
  /* text-align: center; */
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141f33;
  margin-bottom: 0.95rem;
}

.msg .del {
  width: 0.34rem;
  height: 0.34rem;
  float: right;
  margin-right: 0.5rem;
  margin-top: 0.27rem;
}

.msg .wan {
  margin-left: 1.81rem;
}

.hr {
  width: 6.52rem;
  /* background: #ffffff; */
  /* box-shadow: 0rem 0.04rem 0.1rem 0rem #fff1ec; */
  /* border-radius: 0.3rem; */
  margin: 0 0.49rem;
}

.hr .jian {
  height: 0.5rem;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141f33;
  line-height: 0.48rem;
  padding-top: 0.53rem;
  margin-left: 1.32rem;
  margin-bottom: 0.27rem;
}

.hr .jie {
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.52rem;
  margin: 0.82rem 0.33rem 0;
}
</style>